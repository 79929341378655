.login-container {
  min-width: 100%;
  min-height: 100%;
  background-color: rgb(87, 140, 255);
  position: absolute;
}

.login {
  max-width: 22rem;
  width: 90%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.2);
}

.login .login-header {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-top: 50px;*/
}

.login .login-header h1 {
  color: rgb(64, 81, 137);
}

.login .form {
  width: 100%;
}

.login .form form .label {
  display: flex;
  flex-direction: column;
  margin: 25px 0;
}

.login .form form .label label {
  font-size: 14px;
  color: rgb(0, 0, 0, 8);
  margin-bottom: 7px;
}

.login .form form .label input {
  border: 1px solid rgba(0, 0, 0, 0.242);
  outline: none;
  height: 2.4rem;
  font-size: 15px;
  padding-left: 0.8rem;
  border-radius: 4px;
}

.login .form form .label input:focus {
  border: 1px solid rgb(161, 169, 197);
}

.login .form form button {
  color: #fff;
  height: 2.4rem;
  border-radius: 4px;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .login {
    padding: 10px;
  }
}
