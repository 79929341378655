/* Assuming you have a CSS file named chat.css */

.complain-order-history-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: calc(100vh - 12rem);
  /* height: 100%; */
}

.orders-container {
  width: 30%;
  height: calc(100vh - 9rem);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orders-container ul {
  list-style: none;
  margin: 10px;
}

.orders-container ul li {
  background-color: rgb(242, 242, 242);
  padding: 10px;
  border-bottom: 1px solid white;
  cursor: pointer;
}

/* Chat container */
.chat-container {
  /* max-width: 1000px; */
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.active {
  color: #007bff;
}

/* Chat messages area */
.chat-messages {
  /* max-height: 300px; */
  /* width: auto; */
  max-height: calc(100vh - 15rem);
  min-height: calc(100vh - 15rem);
  overflow-y: scroll;
  /* display: flex; */
}

.message-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.message-container.user {
  justify-content: flex-end;
}

.message-container.other {
  justify-content: flex-start;
}

/* Individual chat message */
.message {
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 15px;
  /* max-width: 80%; */
  max-width: 350px;
}

.message.user {
  background-color: #007bff;
  color: #fff;
  justify-content: flex-end;
}

.message.other {
  background-color: #fff;
  color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Chat input area */
.chat-input {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chat-input button {
  padding: 10px 15px;
  margin-left: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-input button:hover {
  background-color: #0056b3;
}

/* Timestamp for messages */
.timestamp {
  font-size: 0.8rem;
  color: #aaa;
  margin: 5px 0;
  text-align: center;
}
