.header {
  width: 100%;
  height: 4rem;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgb(224, 225, 231);
}

.header .left-section {
  margin-left: 25px;
}

.header .left-section button {
  background: transparent;
  outline: none;
  border: none;
  color: rgb(135, 138, 153);
}

.header .right-section {
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.header .right-section .lan {
  margin-right: 20px;
}

.header .right-section .lan p {
  color: rgb(53, 50, 50);
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
}

.header .right-section .lan button {
  background: transparent;
  border: none;
}

.header .right-section .profile {
  max-width: 20rem;
  padding: 0 10px;
  height: 4rem;
  width: 4rem;
  background-color: rgb(243, 243, 249);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.header a {
  text-decoration: none;
}

.header .right-section .profile .profile-picture {
  width: 2.5rem;
  height: 2.5rem;
  /* border-radius: 50%; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header .right-section .profile .profile-picture img {
  width: 2.5rem;
}

.header .right-section .profile-info {
  margin-left: 10px;
}

.header .right-section .profile-info .name {
  color: rgb(73, 85, 108);
  font-size: 14px;
  font-weight: 600;
}

.header .right-section .profile-info .role {
  color: rgb(156, 148, 187);
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .header .left-section {
    margin-left: 10px;
  }

  .header .right-section {
    margin-right: 10px;
  }
}
