.profile-container .right-section {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin: 0 auto;
}

.profile-container .right-section .right-section-header {
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid rgb(233, 235, 236);
  display: flex;
  align-items: center;
}

.profile-container .right-section .right-section-header .right-section-control {
  padding: 0 20px;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-container .right-section .right-section-header .active {
  border-bottom: 1px solid rgb(70, 84, 160);
}

.profile-container
  .right-section
  .right-section-header
  .right-section-control
  button {
  background: transparent;
  outline: none;
  border: none;
  font-size: 13px;
  font-weight: 600;
  color: rgb(70, 84, 160);
}

.profile-container .right-section .right-section-details {
  padding: 0 30px;
}

.profile-container .right-section .right-section-details form {
  width: 100%;
}

.profile-container .right-section .right-section-details form .detail {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.profile-container .right-section .right-section-details form .detail label {
  color: rgba(47, 37, 41, 0.792);
  font-weight: 600;
  font-size: 12px;
}

.profile-container .right-section .right-section-details form .detail input,
.profile-container .right-section .right-section-details form .detail textarea,
.profile-container .right-section .right-section-details form .detail select {
  height: 2rem;
  padding-left: 10px;
  border: 1px solid rgb(183, 189, 194);
  margin-top: 5px;
  color: rgb(0, 0, 0);
  border-radius: 4px;
  outline: none;
}

.profile-container .right-section .right-section-details form .update-button {
  margin: 20px 0;
}

.profile-container
  .right-section
  .right-section-details
  form
  .update-button
  button {
  padding: 6px 15px;
  background: rgb(62, 147, 251);
  border-radius: 4px;
  border: 1px solid rgb(62, 147, 251);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.sorting-container {
  width: 100%;
  height: 4rem;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.sorting-container .sorting select {
  height: 2rem;
  font-size: 16px;
  outline: none;
  padding: 5px;
  margin-left: 20px;
}

.profile-container .right-section .pagination {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
