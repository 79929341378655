.sidebar {
  width: 18rem;
  height: 100vh;
  background-color: #fff;
  transition: all 200ms ease;
  z-index: 1;
  border-right: 1px solid rgb(224, 225, 231);
}

.sidebar .sidebar-header {
  width: 100%;
  height: 4rem;
  text-align: center;
  align-content: center;
  color: white;
}

.sidebar .sidebar-body {
  height: calc(100vh - 4rem);
  overflow-y: auto;
}

.sidebar .sidebar-body::-webkit-scrollbar {
  width: 6px;
}

.sidebar .sidebar-body::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.57);
  border-radius: 4px;
}

.sidebar .sidebar-body ul li {
  padding: 10px;
  margin-left: 10px;
  color: rgb(81, 85, 96);
  list-style: none;
}

.sidebar .sidebar-body ul li .link {
  color: rgb(81, 85, 96);
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidebar .sidebar-body ul li .active {
  color: rgb(62, 147, 251);
}

.sidebar .sidebar-body ul li .link:hover {
  transition: all 200ms ease;
  color: rgb(62, 147, 251);
}

.closed {
  width: 0;
}

.closed .sidebar-body,
.closed .sidebar-header {
  position: absolute;
  left: -100%;
}

@media screen and (max-width: 768px) {
  .sidebar-container-open {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .sidebar {
    position: absolute;
  }

  .closed .sidebar-body,
  .closed .sidebar-header {
    position: relative;
  }
}
